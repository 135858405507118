<template>
  <v-container>
    <v-row>
      <v-col cols="8" offset-lg="2" offset-md="2">
        <Breadcrumbs
          :items="[
            {
              text: 'All Roles',
              disabled: false,
              href: '/app/roles'
            },
            {
              text: !!$route.query.id ? 'Edit Role' : 'Add Role',
              disabled: true,
              href: !!$route.query.id ? '/app/roles/add' : '/app/roles'
            }
          ]"
        />
        <div class="d-flex flex-column mb-3 ml-1 mt-n4">
          <PageTitle
            text="Access Control List"
            class=" font font-weight-bold font-size-lg mb-4"
          />
        </div>
        <div class="d-flex flex-column mx-2 flex-grow-1">
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(addNewRole)">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <v-text-field
                  label="Name of Role"
                  outlined
                  v-model="role.name"
                  hint="Enter the name of the role"
                  class="font font-weight-medium font-size-md"
                  :error-messages="errors[0]"
                />
              </ValidationProvider>
              <ValidationProvider>
                <v-textarea
                  label="Description"
                  v-model="role.description"
                  outlined
                  hint="Enter the description of the role"
                  class="font font-weight-medium font-size-md"
                />
              </ValidationProvider>
              <ValidationProvider rules="required">
                <v-card
                  :loading="isListLoading"
                  class="box-shadow-light"
                  style="border-radius: 5px;"
                >
                  <v-card-title class="primary pa-2">
                    <i class="material-icons-outlined white--text ml-2"
                      >verified_user</i
                    ><span
                      class="white--text pt-1 font font-weight-medium font-size-md ml-3"
                      >Select From A List Of Permissions</span
                    >
                  </v-card-title>
                  <v-card-text>
                    <div
                      class="d-flex flex-row flex-grow-1 pt-3 flex-wrap"
                      style="flex-basis: 100%"
                    >
                      <div
                        v-for="(permission, index) in permissions"
                        :key="index"
                        class="d-flex flex-column"
                        style="flex-basis: 50%"
                      >
                        <span
                          class="font font-weight-medium mt-3 font-size-md primary--text"
                          >{{ permission.key }}</span
                        >
                        <div
                          class="d-flex flex-column mt-n1"
                          v-for="rule in permission.values"
                          :key="rule.id"
                        >
                          <v-checkbox
                            dense
                            :value="rule.id"
                            hide-details
                            v-model="role.permissions"
                            class="font  text-sm"
                          >
                            <template #label>
                              <span class="font text-sm text-capitalize">{{
                                rule.name
                              }}</span>
                            </template>
                          </v-checkbox>
                          <div
                            class="font font-weight-medium"
                            style="font-size: 14px;font-style:italic;"
                          >
                            <q>{{ rule.description }}</q>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      :disabled="isLoading"
                      :loading="isLoading"
                      color="primary"
                      type="submit"
                    >
                      <span class="font font-weight-medium font-size-md"
                        >Save
                      </span>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </ValidationProvider>
            </form>
          </ValidationObserver>
        </div>
      </v-col>
    </v-row>
    <SnackBar
      :showSnackBar="showSnackBar"
      :message="message"
      :status="status"
    />
  </v-container>
</template>
<script>
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import SnackBarMixins from "../../mixins/SnackBarMixins";
import { mapGetters } from "vuex";
extend("required", {
  ...required,
  message: "Field is required"
});
export default {
  name: "AddRole",
  components: {
    ValidationProvider,
    ValidationObserver,
    SnackBar: () => import(`../../components/SnackBar`),
    PageTitle: () => import(`../../components/PageTitle`),
    Breadcrumbs: () => import(`../../components/Breadcrumbs`)
  },
  data() {
    return {
      role: {
        name: "",
        description: "",
        permissions: []
      }
    };
  },
  mixins: [SnackBarMixins],
  computed: {
    ...mapGetters({
      isLoading: "getIsLoading",
      isListLoading: "getIsListLoading",
      permissions: "acl/getListOfPermissions",
      details: "acl/getRoleDetails"
    })
  },
  watch: {
    details(payload) {
      this.role = { ...payload };
    }
  },
  methods: {
    addNewRole() {
      if (!!this.$route.query.id && this.$route.query.action === "edit") {
        this.$store.dispatch("acl/updateRole", this.role);
      } else {
        this.$store.dispatch("acl/createRole", this.role);
      }
    }
  },
  created() {
    if (!!this.$route.query.id && this.$route.query.action === "edit") {
      this.$store.dispatch("acl/details", { id: this.$route.query.id });
    }
    this.$store.dispatch("acl/listOfPermissions");
  }
};
</script>
